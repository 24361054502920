<template>
  <div id="app">
    <div class="content">
      <div class="title">{{ labelName }}</div>
      <div class="list-title">
        当前筛选条件<a-button
          @click="handleEdit(null, -1)"
          type="primary"
          style="margin-left: 20px; width: 120px"
          size="small"
          v-if="hasAction('edit-label-filter')"
          ><a-icon type="plus" style="font-size: 11px" />新增条件</a-button
        >
      </div>
      <ul class="condition-list" v-if="filterData.length > 0">
        <li
          class="condition-list-item"
          v-for="(item, index) in filterData"
          :key="index"
        >
          <a-card :title="'条件' + (index + 1)">
            <a slot="extra" v-if="hasAction('edit-label-filter')"
              ><a-icon
                @click="handleEdit(item, index)"
                type="edit"
                class="colorIcon"
                style="margin-right: 15px"
              ></a-icon
            ></a>
            <a slot="extra" v-if="hasAction('edit-label-filter')">
              <a-popconfirm
                title="确定移除该条件吗？"
                ok-text="是"
                cancel-text="否"
                @confirm="handleDelete(item)"
              >
                <a-icon slot="icon" type="close-circle" style="color: red" />
                <a-icon type="delete"></a-icon>
              </a-popconfirm>
            </a>
            <ul class="sub-condition-list">
              <li
                class="sub-list-item"
                v-for="(subItem, index) in item.conditionData"
                :key="index"
              >
                <a-row>
                  <a-col :span="11"
                    >子条件{{ index + 1 }}：<span>{{
                      subItem.column
                    }}</span></a-col
                  >
                  <a-col :span="3" class="part">
                    <span style="margin-right: 10px">
                      <span v-if="subItem.condition == 'CONTAIN'">包含</span>
                      <span v-else-if="subItem.condition == 'NOT_CONTAIN'"
                        >不包含</span
                      >
                    </span>
                  </a-col>
                  <a-col :span="8">{{ subItem.keyword }}</a-col>
                </a-row>
              </li>
            </ul>
          </a-card>
        </li>
      </ul>
    </div>
  </div>
</template> 

<script>
import storage from "store";

import { getLabelFilterList, deleteLabelFilterByGroup } from "@/api/tag";

export default {
  name: "ConditionManage",
  data() {
    return {
      labelId: null,
      labelName: null,
      filterData: [], //条件列表数据
      columnData: [], //筛选列数据
      curColumnIndex: null,
    };
  },
  created() {
    this.labelId = parseInt(this.$route.query.labelId);
    this.labelName = this.$route.query.labelName;
    this.loadData();
  },
  methods: {
    loadData() {
      let formData = { labelId: this.labelId };
      getLabelFilterList(formData).then((res) => {
        if (res.code === 0) {
          let filterData = [];
          let data = res.data;

          // 根据groupId查询是否已存在对应分组
          const getFilterGroup = function (groupId) {
            const findStatus = function (item) {
              return item.groupId === groupId;
            };
            return (
              (filterData.find(findStatus) && filterData.find(findStatus)) || ""
            );
          };

          // 根据groupId进行数据分组处理
          data.forEach((item) => {
            let currentGroupIndex = getFilterGroup(item.groupId);
            if (currentGroupIndex) {
              let index = filterData.indexOf(currentGroupIndex);
              filterData[index].conditionData.push(item);
            } else {
              let newItem = {
                groupId: item.groupId,
                conditionData: [item],
              };
              filterData.push(newItem);
            }
          });
          this.filterData = filterData;

          // 若弹窗已打开，则刷新弹窗数据
          if (this.curColumnIndex !== null && this.curColumnIndex > -1) {
            this.$refs.modal.edit(filterData[this.curColumnIndex]);
          } else if (this.curColumnIndex === -1) {
            this.$refs.modal.edit(filterData[filterData.length - 1]);
          }
        }
      });
    },
    handleChange(selectedItems) {
      this.selectedItems = selectedItems;
    },
    resetCurColumnIndex() {
      this.curColumnIndex = null;
    },
    handleEdit(item, index) {
      storage.set("CONDITION_INFO", item);
      this.$router.push({
        path: "/system/tag/sub-condition",
        query: {
          labelId: this.labelId,
          labelName: this.labelName,
          index: index > -1 ? index + 1 : -1,
        },
      });

      this.curColumnIndex = index > -1 ? index : -1;
    },
    handleDelete(record) {
      deleteLabelFilterByGroup({ id: record.groupId }).then((res) => {
        if (res.code === 0) {
          this.$message.success("删除成功");
          this.loadData();
        }
      });
    },
  },
};
</script> 

<style scoped lang="less">
.content {
  padding-left: 20px;
}
.title {
  font-size: 26px;
  padding: 12px 0;
  color: #000;
  font-weight: bold;
}
.condition-list {
  padding: 10px 0 0;
  margin-top: 10px;
  color: #888;
  .condition-list-item {
    margin-bottom: 20px;
  }
}
.sub-condition-list {
  .sub-list-item {
    word-break: break-all;
    line-height: 30px;
    .part {
      text-align: center;
    }
  }
}
.list-title {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
}
</style>

<style scoped>
div >>> .ant-modal-footer {
  display: none;
}
</style>